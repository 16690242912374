import React from "react"

import BlogListItem from "./blog-list-item"

const BlogList = (props) => {
	let blogPosts = props.blogPosts;
	let dontShow = props.dontShow;

	return (
		<div className="blog-list">
			{ blogPosts.map( post => {
				if (post.id !== dontShow.id) {
					return( <BlogListItem key={`post-${post.id}`} blogData={post} />);
				} else return false })
			}
		</div>
	)
}

export default BlogList