import React from "react"

import Link from "./link"

const customStyle = (theme,image) => {
	const textColor = theme === "light" ? "black" : "white";
	return {
		color: textColor,
		backgroundImage: 'url(' + image + ')',
	}
}

const BlogListItem = ({blogData}) => {
	const published = blogData.published
		? new Intl.DateTimeFormat('en-GB').format(new Date(blogData.published))
		: null;
	return <Link to={"/blog/" + blogData.slug} className="blog-list-item">
		<div className="image" style={ customStyle(blogData.theme,blogData.image) } />
		<div className="imageGradient" />
		<div className={blogData.theme + " content"}>
			<h2 className="title">{blogData.title}</h2>
			<p className="intro">{blogData.content}</p>
			<div className="details">
				<span className="category">{blogData.category}</span>
				{ published && <span className="date">	• {published}</span>}
				<span className="extras">{ blogData.extras }</span>
			</div>
		</div>
	</Link>
}

export default BlogListItem