import React from "react";
import { graphql } from "gatsby";
import escapeHtml from "escape-html";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Link from "../components/link";
import SocialLinks from "../components/social-links";
import BlogList from "../components/blog-list";

const BlogPost = ({data}) => {
  const post = data.tidealcms.getBlogPost.data;
  const blogPosts = data.tidealcms.listBlogPosts.data;
  const published = post.published
    ? new Intl.DateTimeFormat('en-GB').format(new Date(post.published))
    : null;
  return (<Layout>
    <Seo 
      title={post.title} 
      image= {post.image}
      description={post.ingress}
      url={"/blog/"+post.slug}
    />
    <div id="blogPost">
      <section>
        <h3 className='pageHeader centered'>{post.category}</h3>

        <h1 className='pageTitle'>{post.title}</h1>

        <img className='mainImage' src={post.image} alt="" />

        <h4 className='ingress'>{post.ingress}</h4>

        {published && <h3 className='centered'>{published}</h3>}

        <hr />

        <div className='pageContent'>

          <div className="RTBody">
            <BlogArticleBody
              body={post.body}
              images={post.inlineImages}
              descriptions={post.imageDescriptions}
            />
          </div>

          <div className="shareBox">

            <h3><span>Share</span></h3>

            <SocialLinks url={ ("https://tideal.app/blog/" + post.slug) } />

          </div>

        </div>

        <hr />

        <h1 className="blog-listHeader">More to read</h1>

        <BlogList blogPosts={blogPosts} dontShow={post} />

      </section>

    </div>

  </Layout>)
}

const BlogArticleBody = ({ body, images, descriptions }) => {
  let idx = 0;

  const render = node => {
    const bold = text => {
      return node && node.bold ? `<span class="bold">${text}</span>` : text;
    };
    const italic = text => {
      return node && node.italic ? `<span class="italic">${text}</span>` : text;
    };
    const underline = text => {
      return node && node.underline ? `<span class="underline">${text}</span>` : text;
    };

    if (node.text !== undefined) {
      if (node.code && node.text.startsWith('<')) {
        // special case for printing markup directly from the blog contents
        // mark the text as 'code' in CMS and must start with a <
        return <span key={idx++} dangerouslySetInnerHTML={{ __html: node.text }} />;
      }
      if (node.text.startsWith('<image')) {
        // Image placeholder format is: <image [index]>
        // Search for matching image and description
        // Index in the placeholder is 1-based, reduce 1 to access the array
        const match = /<image\s*(\d{1,})/.exec(node.text);
        if (!match || parseInt(match[1]) < 0) {
          return null;
        }
        const imageIdx = parseInt(match[1]) - 1;
        const image = images.length > imageIdx ? images[imageIdx] : null;
        const description = descriptions.length > imageIdx ? descriptions[imageIdx] : null;
        return <Image key={idx++} image={image} description={description} />;
      }
      // Render basic text. Pass through bold, italic and underline checks
      return (
        <span
          key={idx++}
          dangerouslySetInnerHTML={{ __html: bold(italic(underline(escapeHtml(node.text)))) }}
        />
      );
    }

    if (node.type) {
      const children = { children: node.children };
      switch (node.type) {
        case 'h1':
          return <h1 key={idx++}>{render(children)}</h1>;
        case 'h2':
          return <h2 key={idx++}>{render(children)}</h2>;
        case 'h3':
          return <h3 key={idx++}>{render(children)}</h3>;
        case 'paragraph':
          return <p key={idx++}>{render(children)}</p>;
        case 'description':
          return <p className="lift" key={idx++}><span>{render(children)}</span></p>;
        // case 'primaryColorText':
        //     return <COLORTEXT>${children}</COLORTEXT>;
        case 'link':
          const linkAttrs = {};
          if (node.notfollow) linkAttrs.rel = 'nofollow';
          if (node.newTab) linkAttrs.target = '_blank';
          return (
            <Link key={idx++} href={node.href} {...linkAttrs}>
              {render(children)}
            </Link>
          );
        case 'ordered-list':
          return <ol key={idx++}>{render(children)}</ol>;
        case 'unordered-list':
          return <ul key={idx++}>{render(children)}</ul>;
        case 'list-item':
          return <li key={idx++}>{render(children)}</li>;
        default:
          return null;
      }
    }
    if (node.children) {
      return node.children.map(n => render(n));
    } else {
      return null;
    }
  };

  return render({ children: body });
};

const Image = ({ image, description }) => {
  if (!image) return null;
  return (
    <>
      <img src={image} alt={description} />
      <span className="caption">
        {description}
      </span>
    </>
  );
};

export default BlogPost

export const query = graphql`
query GetPost($slug:String!, $locale:String!) {
  tidealcms {
    getBlogPost(where: { slug: $slug }, locale: $locale) {
      data {
        id,
        title,
        ingress,
        category,
        theme,
        body,
        published,
        image,
        mainImageDescription,
        inlineImages,
        imageDescriptions,
        slug,
        published
      }
    }
    listBlogPosts {
      data {
        id,
        slug,
        title,
        ingress,
        category,
        theme,
        image,
        appHighlight,
        published
      }
    }
  }
}`